<template>
    <a  href="#" 
        @click="submitClick"
    >
        {{menuitem.name}}
         
    </a>
</template>
<script>

export default {
    props:['menuitem'],
    methods:{
        submitClick(){
            this.$store.commit('mainComponentName', this.menuitem.componentName);
            this.$store.commit('vmenuActive', false);
            const prevSelectedHmenuItem = this.$store.state.selectedHmenuItem;
            if (prevSelectedHmenuItem !== null && prevSelectedHmenuItem != this){
                prevSelectedHmenuItem.selected = false;
            }
            this.$store.commit('menuPath', prevSelectedHmenuItem.menuitem.name +'/'+ this.menuitem.name)
            this.$store.commit('selectedHmenuItem', null);
            this.$router.push('/');
        }
    }
}
</script>